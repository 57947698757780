<template>
  <section class="star-rating">
    <h4>
      {{ translations.customer_reviews.average_rating }}:
      <StarRating
        :rating="ratings.average_rating"
        :read-only="true"
        :star-size="40"
        :rounded-corners="true"
        text-class="star-rating__number"
      />
    </h4>
  </section>
</template>

<script>
import StarRating from "vue-star-rating"
import { mapState } from "vuex"
import { CLIENT_STORE } from "../../../../Client/constants/other"

export default {
  props: {
    ratings: {
      type: Object,
      required: true
    }
  },
  components: {
    StarRating
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "translations"
    ])
  },
}
</script>

<style lang="scss">
@use "../../../../../styles/_global-constants" as *;

.star-rating {
  h4 {
    text-align: left;
    font-weight: normal;
  }

  &__number {
    margin-left: 20px !important;
    font-size: 18px;
    font-weight: 600;
  }
}

</style>
