<template>
  <section
    class="list-of-reviews"
    :class="{ 'load-animation-class': visible }"
    v-observe-visibility="(is_visible) => visible = is_visible"
  >
    <div class="list-of-reviews__wrapper">
      <div
        v-for="(review, index) in create_reviews_pagination_object[page_number]"
        :key="`review-${index}`"
        class="list-of-reviews__review"
      >
        <div class="list-of-reviews__section list-of-reviews__section--left">
          <p>{{ review.user_name }}</p>
          <StarRating
            :rating="review.star_rating"
            :read-only="true"
            :star-size="20"
            :rounded-corners="true"
          />
        </div>
        <div class="list-of-reviews__section list-of-reviews__section--middle">
          {{ review.comment }}
          <div v-if="review.images && review.images.length > 0">
            <v-lazy-image
              v-for="(img, img_ind) in review.images"
              :key="`review-img-${img_ind + review.id}`"
              :src="img"
              :src-placeholder="'/static/backgrounds/default-img.jpg'"
              alt="Review img"
              class="list-of-reviews__img"
              :class="{
                'list-of-reviews__img--expanded': img_ind + review.id === expanded_img_index
              }"
              @click.native="img_ind + review.id === expanded_img_index ? schrink_img() : expand_img(img_ind + review.id)"
              itemprop="image"
            />
          </div>
        </div>
      </div>
      <div
        v-if="create_pagination_array.length > 0"
        class="list-of-reviews__paginator"
      >
        <div
          v-if="create_pagination_array.length > 1 && page_number > 1"
          class="list-of-reviews__paginator__arrow list-of-reviews__paginator__arrow--left"
          @click="select_page(false)"
        />
        <div class="list-of-reviews__paginator__numbers">
          <p
            v-for="number in create_pagination_array"
            :key="`pagination-number-${number}`"
            :style="{
              fontWeight: page_number === number ? 600 : 300
            }"
            @click="select_page(number)"
          >
            {{ number }}
          </p>
        </div>
        <div
          v-if="create_pagination_array.length > 1 && page_number < create_pagination_array.length"
          class="list-of-reviews__paginator__arrow list-of-reviews__paginator__arrow--right"
          @click="select_page(true)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import StarRating from "vue-star-rating"

export default {
  props: {
    reviews: {
      type: Array,
      required: true
    }
  },
  components: {
    StarRating,
    VLazyImage
  },
  data() {
    return {
      expanded_img_index: null,
      page_number: 1,
      visible: false,
      number_of_reviews_per_page: 3
    }
  },
  computed: {
    create_reviews_pagination_object() {
      let number_of_needed_arrays = Math.round(this.reviews.length / this.number_of_reviews_per_page)
      const remainder_of_reviews = this.reviews.length % this.number_of_reviews_per_page

      // If there are any left over elements add an extra array
      if(remainder_of_reviews > 0)
        number_of_needed_arrays += 1

      const array_of_needed_lenght = Array.apply(null, { length: number_of_needed_arrays })
      const function_for_slicing = (index, back_stop) => this.reviews.slice(index * this.number_of_reviews_per_page, back_stop)

      return array_of_needed_lenght
          .reduce((total, _, index) => {
            if(remainder_of_reviews > 0)
              return {
                ...total,
                [index + 1]: array_of_needed_lenght === (index + 1) ?
                  function_for_slicing(index, remainder_of_reviews) :
                  function_for_slicing(index, (index + 1) * this.number_of_reviews_per_page)
              }


            return {
              ...total,
              [index + 1]: function_for_slicing(index, (index + 1) * this.number_of_reviews_per_page)
            }
          }, {})
    },
    /**
     * 
     */
    create_pagination_array() {
      return Array
        .apply(null, { length: Object.keys(this.create_reviews_pagination_object).length })
        .map(Number.call, Number)
        .map(num => num + 1)
    }
  },
  methods: {
    expand_img(index) {
      this.expanded_img_index = index
    },
    /**
     * 
     */
    schrink_img() {
      this.expanded_img_index = null
    },
    /**
     * 
     */
    select_page(value) {
      if(typeof value === "boolean")
        value ? this.page_number += 1 : this.page_number -= 1
      else
        this.page_number = value
    }
  },
}
</script>

<style lang="scss" scoped>
@use "../../../../../styles/_global-constants" as *;

.list-of-reviews {
  text-align: left;

  &__review {
    display: flex;
    margin-bottom: 15px;
    background: $pure-white;
    border-top: 1px solid $grey;
    box-shadow: -1px 13px 16px -5px $shadow-color-light;

    &:last-child {
      border-bottom: 1px solid $grey;
    }

    @media (max-width: $tablet) {
      display: block;
    }
  }

  &__section {
    display: inline-block;
    max-width: 20%;
    padding: 10px 30px;
    box-sizing: border-box;
    vertical-align: middle;

    @media (max-width: $tablet) {
      display: block;
      width: 100%;
      padding: 10px 20px;
      max-width: 100%;
      text-align: left;

      p {
        margin: 0;
      }
    }

    &--middle {
      max-width: 80%;
      border-left: 1px solid $grey;

      @media (max-width: $tablet) {
        width: 100%;
        max-width: 100%;
        border-top: 1px solid $grey;
        border-left: 0px;
      }
    }
  }

  &__img {
    display: inline-block;
    max-height: 100px;
    max-width: 100px;
    margin: 10px 10px 0 0;
    transition: 250ms ease-out;
    cursor: pointer;
    vertical-align: middle;

    &--expanded {
      max-height: 400px;
      max-width: 400px;

      @media (max-width: $tablet) {
        max-width: 100%;
      }
    }
  }

  &__paginator {
    text-align: center;
    margin: 0 20px;

    &__numbers {
      display: inline-block;
      vertical-align: middle;

      @media (max-width: $tablet) {
        font-size: 18px;
      }

      p {
        display: inline-block;
        margin: 0 10px 0 0;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }
      }
    }
  
    &__arrow {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("/static/icons/arrow.svg");
      background-size: 100% 100%;
      cursor: pointer;
      vertical-align: middle;

      @media (max-width: $tablet) {
        width: 30px;
        height: 30px;
      }

      &--left {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
