<template>
  <section
    v-if="get_revies_array.length > 0"
    class="customer-reviews"
    :class="{ 'customer-reviews__fullscreen': fullscreen }"
  >
    <h3>{{ translations.customer_reviews.customer_reviews }} <span v-if="!fullscreen">({{ get_star_rating_information.number_of_ratings }}): </span></h3>
    <div v-if="loading_reviews" class="customer-reviews__loading">
      <Loader />
    </div>
    <div v-else>
      <star_ratings :ratings="get_star_rating_information"/>
      <list_of_reviews :reviews="get_revies_array"/>
      <new_review_form />
    </div>
  </section>
</template>

<script>
import Loader from "@/applications/Shared/components/Loader/index.vue"
import { mapState } from "vuex"
import star_ratings from "./star_ratings"
import list_of_reviews from "./list_of_reviews"
import new_review_form from "./new_review_form"

export default {
  props: {
    product_id: {
      type: String | Number,
      required: true,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    star_ratings,
    list_of_reviews,
    new_review_form,
    Loader
  },
  data() {
    return {
      item_reviews_object: {},
      loading_reviews: false
    }
  },
  computed: {
    ...mapState("Client", [
      "translations"
    ]),
    /**
     * 
     */
    get_revies_array() {
      return Object.values(this.item_reviews_object)
    },
    /**
     * 
     */
    get_star_rating_information() {
      const array_of_ratings = Object
        .values(this.item_reviews_object)
      const get_average_rating = Math.round(
        array_of_ratings
          .reduce(
            (total, { star_rating }) => total + (star_rating ? star_rating : 5)
            , 0
          ) / array_of_ratings.length
      )

      const get_number_of_ratings = Object
        .values(this.item_reviews_object).length

      return {
        average_rating: get_average_rating >= 0 ? get_average_rating : 0,
        number_of_ratings: get_number_of_ratings
      }
    }
  },
  methods: {
    fetch_item_reviews() {
    }
  },
}
</script>

<style lang="scss" scoped>
@use "../../../../../styles/_global-constants" as *;

.customer-reviews {
  min-height: 300px;

  @media (max-width: $tablet) {
    min-height: 200px;
  }

  h3 {
    margin: 40px 0 20px;
    font-weight: 400;
    text-align: center;
  }
  
  &__loading {
    .spinner {
      margin: auto;

      &-inner {
        &.loading {
          transform: scale(1.2) !important;
        }
      }
    }
  }

  &__fullscreen {
    margin-top: 90px;

    h3 {
      padding: 20px;
      font-size: 30px;
      border-bottom: 2px solid var(--background_heavy);
    }
  }
}
</style>
